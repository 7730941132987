export const CONFIG = {
    publihserList: [
        {
            "publisher": "ukhurltimes",
            "domain": "ukhurlTimes"
        },
        {
            "publisher": "happiesthealth",
            "domain": "www.happiesthealth.com"
        },
        {
            "publisher": "creativegaga",
            "domain": "www.creativegaga.com"
        },
        {
            "publisher": "happiestminds",
            "domain": "www.happiestminds.com"
        },
        {
            "publisher": "zee",
            "domain": "www.techlusive.in"
        },
        {
            "publisher": "aishortvid",
            "domain": "aishortvid.kenpath.io"
        }
    ],
    widgetEventOrder: [
        "Number of Widget Loads",
        "Number of individual slides viewed",
        "Total Number of Ads Loaded",
        "Total Number of Ads Completed",
        "Number of Skips"
    ]
}

